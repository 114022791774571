<template>
</template>
<script>
export default {
    beforeCreate(){
        parent?.window?.billgateComplete?.(this.$route.query);
        opener?.window?.billgateComplete?.(this.$route.query);
        if(parent || opener) window.close();
    },
}
</script>